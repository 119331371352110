#danke
    min-height: calc(100vh - 130px)
    width: 100%
    background: $gradient4
    display: flex
    align-items: center
    .danke
        max-width: 1140px
        width: 90%
        padding: 30px
        margin: 0 auto
        h1
            color: white
            font-size: 4rem
            line-height: 1
        h3
            color: white
            font-size: 2rem
        a
            background: #fff
            color: $rot
            padding: 20px 40px
            border-radius: 50px
            margin-top: 30px
            font-size: 1.3rem
            font-family: $font-family-headline
            display: inline-block
        @media (max-width: 980px)
            h1
                font-size: 2rem
            h3
                font-size: 1.4rem