.btn-outline
    color: $rot
    border: 2px solid $rot
    width: 100%
    display: block
    height: 41px
    border-radius: 100px
    cursor: pointer
    margin-bottom: 10px
    font-size: 0.925rem
    line-height: 41px
    @media (max-width: 400px)
        font-size: 0.7rem
    &:hover
        background: $rot
        color: white
.btn-outline-input
    color: $rot
    border: 2px solid $rot
    width: 100%
    display: block
    height: 41px
    border-radius: 100px
    margin-bottom: 10px
    font-size: 0.925rem
    line-height: 41px
    outline: none
    text-align: center
    &:hover
        border: 2px solid $lila

.btn
    color: white
    width: 100%
    display: block
    height: 41px
    line-height: 41px
    border-radius: 100px
    cursor: pointer
    margin: 20px 0 10px
    font-size: 0.825rem
    font-family: $font-family-headline
    background: $gradient3
    text-align: center
    outline: none
    border: none

    &:hover
        background: $gradient1
        color: white
    &.inactive
        // pointer-events: none
        &:hover
            background: $gradient3
.btn-submit
    color: white
    width: 100%
    display: block
    line-height: 1
    border-radius: 100px
    cursor: pointer
    margin: 20px 0 10px
    font-size: 0.825rem
    font-family: $font-family-headline
    background: $gradient3
    text-align: center
    outline: none
    border: none
    padding: 10px 0
    span
        color: inherit
        font-size: inherit
        display: inline-block
        max-width: 240px
        &.large
            font-size: 1rem
            font-family: $font-family-headline

    &:hover
        background: $gradient1
        color: white
    &.inactive
        // pointer-events: none
        &:hover
            background: $gradient3

.btn-large
    color: white
    display: inline-block
    border-radius: 100px
    cursor: pointer
    padding: 10px 60px
    margin: 20px 0 10px
    font-size: 1.2rem
    font-family: $font-family-headline
    background: $gradient3
    text-align: center
    outline: none
    border: none

    &:hover
        background: $gradient1
        color: white
