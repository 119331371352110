#zusatzversichert
    padding: 100px 40px
    background: $hellgrau
    .soft-row
        max-width: 1140px
        margin: 0 auto
        justify-content: space-between
        h2
            margin-bottom: 60px
        .col-1
            min-width: 320px
            max-width: 320px
            margin-left: 60px
            @media (max-width: 1224px)
                min-width: 90%
                margin-bottom: 50px
                &:last-child
                    margin-bottom: 0px
                    h3
                        margin-bottom: 15px !important
                h3
                    &:first-of-type
                        margin-bottom: 15px !important
            @media (max-width: 400px)
                min-width: 80%
                margin-bottom: 50px
            &:first-of-type, &:last-child
                h3
                    margin-bottom: 43px

            &:before
                content: url('../img/icn_list.svg')
                display: block
                position: absolute
                width: 40px
                height: 40px
                left: -60px
            .soft-row
                align-items: center
                margin-bottom: 30px
                h2
                    color: $rot
                    font-family: $font-family-headline-light
                    flex: 1 0 0
                    margin-bottom: 0
                    margin-left: 20px
                    font-size: 2.5em
                    @media (max-width: 787px)
                        font-size: 1.3em
                    @media (max-width: 696px)
                        font-size: 2em
                    strong
                        font-family: $font-family-headline
                        font-size: inherit
            p 
                font-size: 0.9rem
                @media (max-width: 980px)
                    font-size: 0.9rem
                @media (max-width: 350px)
                    margin-right: 20px
            h3
                font-size: 1.4rem
                font-family: 'univers'
                font-weight: bold
                color: $rot

                .soft-row
                    align-items: stretch
                    width: 100%
                    .ausnahme
                        min-width: 200px
                    .col-1 
                        padding: 10px
                        .zitat
                            padding: 10px
                            height: 100%
                            min-width: 170px
                            max-width: 100%
                            background: #F8F8F8
                            p
                                font-size: 0.7rem
                                text-align: left
                                margin: 10px 0
                    
                                &.name
                                    font-size: 0.6rem
                                    opacity: 0.8
                                    margin-bottom: 0
                            .sterne
                                display: flex
                                justify-content: space-between
                                align-items: center
                                img
                                    width: 90px
                                p
                                    font-size: 0.5rem
    @media (max-width: 400px)
        padding: 40px