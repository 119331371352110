.cookie--badge
    position: fixed
    right: 10px
    bottom: 10px
    border-radius: 50%
    width: 50px
    height: 50px
    display: flex
    align-items: center
    justify-content: center
    background: $rot
    cursor: pointer
    box-shadow: 0 0 10px rgba(black, 0.15)
    @media (min-width: 1200px)
        z-index: 20
    
// Cookiebot
@media (max-width: 600px)
    .CybotCookiebotDialogBodyLevelButtonWrapper
        display: block!important
#CybotCookiebotDialogDetailFooter a
    font-size: inherit!important
#CybotCookiebotDialogDetailBodyContentTabs a
    font-size: 12px!important
#CybotCookiebotDialog
    *
        font-size: 9.5pt
    position: fixed
    top: 50% !important
    left: 50% !important
    transform: translate(-50%,-50%) !important
    width: 90% !important
    max-width: 610px !important
    box-shadow: 0 0 100vw 100vw rgba(0,0,0,0.5)!important
    padding: 20px!important
    box-sizing: border-box!important
    #CybotCookiebotDialogBodyLevelDetailsButton
        font-weight: normal!important
        text-decoration: none!important
    #CybotCookiebotDialogBody
        padding: 0
        width: 100%
        max-width: 100%
    #CybotCookiebotDialogPoweredbyLink
        display: none
    #CybotCookiebotDialogBodyContent
        padding: 0
        padding-bottom: 20px
    *
        font-family: $font!important
    #CybotCookiebotDialogBodyLevelWrapper
        display: flex!important
        flex-wrap: wrap
        justify-content: flex-start
    #CybotCookiebotDialogBodyLevelButtonsSelectPane
        padding: 0!important
        border: none!important
    #CybotCookiebotDialogBodyLevelDetailsWrapper
        padding: 0!important
        background: white!important
        border: none!important
        text-align: right!important


    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper
        order: 2
        display: flex !important
        justify-content: space-between
        align-items: flex-end
        width: 100%
        #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
        #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll
            display: flex !important
            padding: 10px 20px !important
            align-items: center
            justify-content: center
            margin: 10px 0 0 10px !important
        #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection
            background: white !important
            border: none !important
            padding-left: 0 !important
            padding-bottom: 0 !important
            color: #707070!important
            margin-left: 0!important
            justify-content: flex-start
            font-weight: normal!important
        #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll
            background: $rot !important
            border: 1px solid $rot !important

    #CybotCookiebotDialogBodyLevelButtons
        margin: 0 !important
        width: 100%

   