@font-face 
	font-family: 'value'
	src: url('../fonts/value/Value-Regular.woff2') format('woff2'), url('../fonts/value/Value-Regular.woff') format('woff')
	font-weight: normal
	font-style: normal


@font-face 
	font-family: 'valueBold'
	src: url('../fonts/value/Value-Bold.woff2') format('woff2'), url('../fonts/value/Value-Bold.woff') format('woff')
	font-weight: bold
	font-style: normal


@font-face 
	font-family: 'univers'
	src: url('../fonts/univers/UniversLTStd.woff2') format('woff2'), url('../fonts/univers/UniversLTStd.woff') format('woff')
	font-weight: normal
	font-style: normal


@font-face 
	font-family: 'univers'
	src: url('../fonts/univers/UniversLTStd-Bold.woff2') format('woff2'), url('../fonts/univers/UniversLTStd-Bold.woff') format('woff')
	font-weight: bold
	font-style: normal

@font-face 
	font-family: 'chalk'
	src: url('../fonts/chalk/VerySimpleChalk.woff2') format('woff2'), url('../fonts/chalk/VerySimpleChalk.woff') format('woff')
	font-weight: normal
	font-style: normal


*, p, strong, ul, li, a
	font-family: $body-font-family
	color: inherit

p
	color: $font

h1, h2, h3, h4
	font-family: $font-family-headline
	line-height: 1.2


h1, h2
	font-size: 3rem
	margin-bottom: 30px
	@media (max-width: 760px)
		font-size: 1.6rem
	@media (max-width: 450px)
		font-size: 1.4rem
	color: $rot
h3
	margin-bottom: 15px
	@media (max-width: 760px)
		font-size: 1.3rem
	@media (max-width: 450px)
		font-size: 1.1rem
h4
	@media (max-width: 510px)
		font-size: 0.7em