#corona
    background: $rot
    .content
        padding: 10px
        width: 100%
        max-width: 1400px
        margin: 0 auto
    *
        color: white
    h2
        text-align: center
        margin: 0
        font-size: 22px
        white-space: break-spaces
        @media (min-width: 840px)
            font-size: 25px
            line-height: 1.2
    .corona--grid
        display: grid
        align-items: center
        grid-template-areas: 'text text text text' 'desinfektion waschen handschuh mundschutz'
        grid-template-columns: 1fr 1fr 1fr 1fr
        grid-template-rows: auto auto
        grid-gap: 10px
        .grid--item
            text-align: center
            img
                max-width: 80px
            
        .desinfektion
            grid-area: desinfektion
        .waschen
            grid-area: waschen
        .text
            -ms-grid-row: 1!important
            -ms-grid-column: 1!important
            -ms-grid-column-span: 7!important
            grid-area: text

        .handschuh
            grid-area: handschuh
        .mundschutz
            grid-area: mundschutz
        @media (min-width: 840px)
            grid-template-areas: 'desinfektion waschen text handschuh mundschutz'
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr
            grid-template-rows: auto
            .grid--item
                &.text
                    -ms-grid-column: 3!important
                    -ms-grid-column-span: 1
            


   