.container
    max-width: 1200px
    margin: 0 auto
    

.row
    padding: 30px
    display: block
.soft-row
    display: flex
    flex-wrap: wrap
    
.col-1
    flex: 1 0 0
.col-2
    flex: 2 0 0 
.col-3
    flex: 3 0 0 
.col-4
    flex: 4 0 0 
.col-5
    flex: 5 0 0 
.col-6
    flex: 6 0 0 

hr
    margin: 20px 0
.bodyContent
    // max-width: 1200px
    // margin: 0 auto 30px
    background: #fff
    top: 100px
    @media (max-width: 940px)
        top: 135px
    @media (max-width: 695px)
        top: 175px
    
body
    background: #ececec