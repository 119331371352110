footer
    height: auto
    display: flex
    justify-content: space-between
    background: #1A1A1A
    padding: 30px 0
    p
        font-size: 0.825rem
        color: white
        display: flex
        align-items: center
        padding: 0 40px
        @media (max-width: 980px)
            display: block
    span
        color: #696969
        font-size: 0.825rem
    .legal
        a
            font-size: 0.825rem
            margin-right: 30px
    @media (max-width: 980px)
        display: block
        text-align: center
        .legal
            margin-bottom: 10px
            p, a
                font-size: 0.725rem
        p, a
            font-size: 0.725rem
    @media (max-width: 940px)
        text-align: center

.footer-cookie
    margin-bottom: 87px
    @media (max-width: 799px)
        margin-bottom: 140px
    @media (max-width: 570px)
        margin-bottom: 170px
    @media (max-width: 400px)
        margin-bottom: 220px