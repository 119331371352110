@font-face {
  font-family: "value";
  src: url("../fonts/value/Value-Regular.woff2") format("woff2"), url("../fonts/value/Value-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "valueBold";
  src: url("../fonts/value/Value-Bold.woff2") format("woff2"), url("../fonts/value/Value-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "univers";
  src: url("../fonts/univers/UniversLTStd.woff2") format("woff2"), url("../fonts/univers/UniversLTStd.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "univers";
  src: url("../fonts/univers/UniversLTStd-Bold.woff2") format("woff2"), url("../fonts/univers/UniversLTStd-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "chalk";
  src: url("../fonts/chalk/VerySimpleChalk.woff2") format("woff2"), url("../fonts/chalk/VerySimpleChalk.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
*, p, strong, ul, li, a {
  font-family: "univers", Helvetica, Arial, sans-serif;
  color: inherit;
}

p {
  color: #707070;
}

h1, h2, h3, h4 {
  font-family: "valueBold", Helvetica, Arial, sans-serif;
  line-height: 1.2;
}

h1, h2 {
  font-size: 3rem;
  margin-bottom: 30px;
  color: rgb(197, 0, 62);
}
@media (max-width: 760px) {
  h1, h2 {
    font-size: 1.6rem;
  }
}
@media (max-width: 450px) {
  h1, h2 {
    font-size: 1.4rem;
  }
}

h3 {
  margin-bottom: 15px;
}
@media (max-width: 760px) {
  h3 {
    font-size: 1.3rem;
  }
}
@media (max-width: 450px) {
  h3 {
    font-size: 1.1rem;
  }
}

@media (max-width: 510px) {
  h4 {
    font-size: 0.7em;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4;
  color: #333;
  transition: all 300ms ease;
  position: relative;
  font-size: 17px;
}

nav {
  height: 100px;
  width: 100%;
  background: #ffffff;
  position: fixed;
  z-index: 99999;
  border-bottom: 2px solid #dedede;
}
@media (max-width: 1250px) {
  nav {
    padding: 0 30px;
  }
}
nav .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
}
nav .container #logo {
  height: 50px;
  display: block;
}
nav .container #logo img {
  height: 100%;
}
nav .container #logo p {
  display: none !important;
  color: white;
  font-size: 1em;
  line-height: 0;
  text-align: center;
  margin-top: 4px;
}
@media (max-width: 1024px) {
  nav .container #logo p {
    font-size: 0.8em;
  }
}
nav .container #logo2 {
  position: relative;
}
nav .container #logo2 img {
  height: 50px;
  display: block;
}
@media (max-width: 700px) {
  nav .container #logo2 img {
    height: 30px;
  }
}
nav .container #trennstrich {
  width: 4px;
  height: 60%;
  margin: 0 25px;
  background: #dedede;
}
nav .container #menu {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
nav .container #menu .zufriedenheit {
  max-height: 85%;
  display: inline-block;
  margin-right: 20px;
}
nav .container #menu .hotline {
  height: 100%;
  align-items: center;
  display: grid;
  text-align: end;
}
@media (max-width: 1140px) {
  nav .container #menu .hotline {
    padding: 10px 20px;
  }
}
nav .container #menu .hotline h3 {
  font-size: 1.8rem;
  color: rgb(197, 0, 62);
  margin-bottom: 0px;
  display: inline-block;
}
nav .container #menu .hotline h3 .phone {
  width: 27px;
  position: relative;
  top: 3px;
}
nav .container #menu .hotline .top-menu-wrap {
  display: flex;
  align-items: center;
}
nav .container #menu .hotline .top-menu-wrap .top-menu {
  font-size: 0.8rem;
  margin-left: 20px;
  display: flex;
  align-items: flex-end;
  line-height: 1;
}
nav .container #menu .hotline .top-menu-wrap .top-menu:hover {
  color: rgb(197, 0, 62);
}
nav .container #menu .hotline .top-menu-wrap .top-menu:before {
  display: inline-flex;
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
nav .container #menu .hotline .top-menu-wrap .terminIcon:before {
  content: url("../img/i_termin.svg");
}
nav .container #menu .hotline .top-menu-wrap .filialIcon:before {
  content: url("../img/i_filiale.svg");
}
nav .container #menu .hotline .top-menu-wrap .hoertestIcon:before {
  content: url("../img/i_hoertest.svg");
}
@media (max-width: 940px) {
  nav {
    height: auto;
    padding: 0;
  }
  nav .container {
    padding-left: 0;
    display: inline-flex;
    text-align: center;
    flex-wrap: wrap;
    justify-content: center;
  }
  nav .container #trennstrich {
    width: 2px;
    height: 58px;
    margin: 0;
  }
  nav .container #logo, nav .container #logo2 {
    position: relative;
    height: 70px;
    margin: 0;
    padding: 21px;
  }
  nav .container #logo img, nav .container #logo2 img {
    height: 28px;
  }
  nav .container #logo2 {
    padding: 0 21px;
    display: flex;
    align-items: center;
  }
  nav .container #logo2 img {
    height: 30px;
  }
  nav .container #menu {
    background: #dedede;
  }
  nav .container #menu .zufriedenheit {
    display: none;
    width: 100%;
  }
  nav .container #menu .hotline {
    padding: 12px;
    width: 100%;
    margin-left: 0;
    display: flex;
    align-items: center;
    height: auto;
    justify-content: center;
    margin: 0;
    flex-wrap: wrap;
  }
  nav .container #menu .hotline a:last-of-type {
    margin-left: 50px;
  }
  nav .container #menu .hotline .top-menu-wrap {
    min-width: 420px;
  }
}
@media (max-width: 940px) and (max-width: 695px) {
  nav .container #menu .hotline .top-menu-wrap {
    margin-bottom: 20px;
  }
}
@media (max-width: 940px) and (max-width: 500px) {
  nav .container #menu .hotline .top-menu-wrap {
    min-width: 300px;
  }
}
@media (max-width: 940px) {
  nav .container #menu .hotline h3 {
    font-size: 1.3rem;
    margin-right: 20px;
    margin-bottom: 0;
  }
  nav .container #menu .hotline h3 .phone {
    width: 20px;
  }
  nav .container #menu .hotline .sternchen {
    margin-bottom: 0;
  }
  nav .container #menu .hotline p:not(.sternchen) {
    display: none;
    font-size: 0.825rem;
  }
}

#huk--link,
#vrk--link {
  position: absolute;
  top: 0;
  height: 100%;
}

#huk--link {
  left: 0;
  width: 70%;
}

#vrk--link {
  left: 70%;
  width: 30%;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.row {
  padding: 30px;
  display: block;
}

.soft-row {
  display: flex;
  flex-wrap: wrap;
}

.col-1 {
  flex: 1 0 0;
}

.col-2 {
  flex: 2 0 0;
}

.col-3 {
  flex: 3 0 0;
}

.col-4 {
  flex: 4 0 0;
}

.col-5 {
  flex: 5 0 0;
}

.col-6 {
  flex: 6 0 0;
}

hr {
  margin: 20px 0;
}

.bodyContent {
  background: #fff;
  top: 100px;
}
@media (max-width: 940px) {
  .bodyContent {
    top: 135px;
  }
}
@media (max-width: 695px) {
  .bodyContent {
    top: 175px;
  }
}

body {
  background: #ececec;
}

header {
  width: 100%;
}
header .image {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: url("../img/hero-image.jpg") left center no-repeat;
  background-size: cover;
}
header .content {
  margin: 0 auto;
  height: 45vw;
  max-height: 750px;
  min-height: 600px;
  display: flex;
  flex-wrap: wrap;
}
header .content #scroll-down {
  bottom: 5%;
  margin: 0 auto;
  align-self: flex-end;
  width: 40px;
}
header .content .header-zitat {
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  bottom: 15%;
  left: 0;
  padding: 15px 30px;
  max-width: 1000px;
}
header .content .header-zitat p {
  font-size: 2vw;
  font-family: "value";
  color: black;
}
header .content .header-zitat strong {
  font-size: 2.25vw;
  font-family: "valueBold";
  color: black;
}
@media (max-width: 600px) {
  header .content .header-zitat p {
    font-size: 3vw;
  }
  header .content .header-zitat strong {
    font-size: 3.25vw;
  }
}
@media (max-width: 980px) {
  header .content {
    max-height: 100%;
    min-height: 100%;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
header .content .col-1 {
  min-width: 300px;
}
header .content .col-1 h1 {
  margin-top: 50px;
  color: rgb(197, 0, 62);
  font-size: 2.3rem;
  margin-left: 60px;
  margin-bottom: 50px;
}
header .content .col-1 .headline h2 {
  color: white;
  margin-left: 60px;
  font-family: chalk;
  font-weight: 100;
  font-size: 2.5rem;
}
@media (max-width: 600px) {
  header .image {
    height: 60vh !important;
    background: url("../img/hero-image.jpg") center center no-repeat;
    background-size: cover;
  }
}
@media (max-width: 980px) {
  header .image {
    width: 100%;
    height: 50vw;
    position: relative;
  }
  header .content {
    height: auto;
    padding-bottom: 20px;
  }
  header .content .col-1 h1 {
    margin: 0;
    padding: 20px;
    font-size: 1.8rem;
    color: rgb(197, 0, 62);
    text-align: center;
    position: absolute;
    top: -43%;
    width: 300px;
    text-align: left;
    z-index: 9999999999;
    right: 5%;
  }
}
@media (max-width: 980px) and (max-width: 600px) {
  header .content .col-1 h1 {
    top: -27%;
    right: -3%;
    font-size: 1.4rem;
    width: 200px;
  }
}
@media (max-width: 980px) and (max-width: 350px) {
  header .content .col-1 h1 {
    top: -25%;
    right: -9%;
    font-size: 1.4rem;
    width: 200px;
  }
}
@media (max-width: 980px) {
  header .content .col-1 .headline h2 {
    color: black;
    text-align: center;
  }
  header .content .col-1 form {
    margin-left: 0;
    margin: 0 10px 10px 10px;
  }
}
@media (max-width: 980px) and (max-width: 500px) {
  header .content .col-1 form {
    margin: 10px 10px;
  }
}

hr {
  width: 100%;
  margin: 0;
  padding: 0;
  background: linear-gradient(to right, rgb(197, 0, 62), rgb(85, 15, 80));
  height: 7px;
}

.headercontent {
  padding: 40px;
}
.headercontent .soft-row {
  max-width: 1140px;
  margin: 0 auto;
  padding: 40px 0;
}
.headercontent .soft-row .col-1 ul li {
  font-size: 1.5rem;
  font-family: "valueBold", Helvetica, Arial, sans-serif;
  margin: 0 0 20px 40px;
  position: relative;
}
.headercontent .soft-row .col-1 ul li:last-child {
  margin-bottom: 0;
}
.headercontent .soft-row .col-1 ul li:before {
  content: "";
  width: 30px;
  height: 25px;
  position: absolute;
  left: -40px;
  top: 3px;
  background: url("../img/icn_list.svg") center no-repeat;
  background-size: contain;
}
@media (max-width: 980px) {
  .headercontent .soft-row {
    display: block;
  }
  .headercontent .soft-row .col-1 ul li {
    font-size: 1rem;
    margin-left: 20px;
  }
  .headercontent .soft-row .col-1 ul li:before {
    width: 20px;
    top: 2px;
    left: -25px;
  }
}
@media (max-width: 400px) {
  .headercontent .soft-row {
    padding: 0px;
  }
}

footer {
  height: auto;
  display: flex;
  justify-content: space-between;
  background: #1A1A1A;
  padding: 30px 0;
}
footer p {
  font-size: 0.825rem;
  color: white;
  display: flex;
  align-items: center;
  padding: 0 40px;
}
@media (max-width: 980px) {
  footer p {
    display: block;
  }
}
footer span {
  color: #696969;
  font-size: 0.825rem;
}
footer .legal a {
  font-size: 0.825rem;
  margin-right: 30px;
}
@media (max-width: 980px) {
  footer {
    display: block;
    text-align: center;
  }
  footer .legal {
    margin-bottom: 10px;
  }
  footer .legal p, footer .legal a {
    font-size: 0.725rem;
  }
  footer p, footer a {
    font-size: 0.725rem;
  }
}
@media (max-width: 940px) {
  footer {
    text-align: center;
  }
}

.footer-cookie {
  margin-bottom: 87px;
}
@media (max-width: 799px) {
  .footer-cookie {
    margin-bottom: 140px;
  }
}
@media (max-width: 570px) {
  .footer-cookie {
    margin-bottom: 170px;
  }
}
@media (max-width: 400px) {
  .footer-cookie {
    margin-bottom: 220px;
  }
}

#zeit {
  margin: 0 auto;
  padding: 50px 0;
  max-width: 1140px;
}
#zeit .container {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}
#zeit .container .col-1 {
  flex: 1 0 0;
  min-width: 300px;
  width: 50%;
}
@media (max-width: 350px) {
  #zeit .container .col-1 ul {
    margin-right: 10px;
  }
}
#zeit .container .col-1 ul li {
  font-size: 1.5rem;
  font-family: "univers", Helvetica, Arial, sans-serif;
  margin: 0 0 20px 40px;
  position: relative;
}
#zeit .container .col-1 ul li:last-child {
  margin-bottom: 0;
}
#zeit .container .col-1 ul li:before {
  content: "";
  width: 30px;
  height: 25px;
  position: absolute;
  left: -40px;
  top: 3px;
  background: url("../img/icn_list.svg") center no-repeat;
  background-size: contain;
}
@media (max-width: 980px) {
  #zeit .container .col-1 ul li {
    font-size: 1rem;
    margin-left: 20px;
  }
  #zeit .container .col-1 ul li:before {
    width: 20px;
    top: 2px;
    left: -25px;
  }
}
#zeit .container .col-2 {
  flex: 1 0 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
}
#zeit .container img {
  width: 75%;
}
#zeit video:-webkit-media-controls-overlay-play-button {
  display: none;
}
#zeit #video video {
  margin-top: 70px;
  width: 100%;
  max-width: 1200px;
}
#zeit #video p {
  text-align: center;
  font-size: 0.825em;
  margin-top: 10px;
}
#zeit #video .playpause {
  background-image: url(../img/play.png);
  background-repeat: no-repeat;
  width: 50%;
  height: 50%;
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  margin: auto;
  background-size: contain;
  background-position: center;
}
@media (max-width: 500px) {
  #zeit #video .playpause {
    width: 20%;
    height: 20%;
  }
}
#zeit #hoergeraete h3 {
  text-align: center;
}
#zeit #hoergeraete .container {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}
#zeit #hoergeraete .container .col-1 {
  flex: 1 0 0;
  width: 50%;
}
#zeit #hoergeraete .container .col-2 {
  flex: 1 0 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: auto;
}
#zeit #hoergeraete .container img {
  max-width: 200px;
  height: auto;
}
@media (max-width: 1200px) {
  #zeit {
    padding: 40px;
  }
}
@media (max-width: 320px) {
  #zeit {
    padding: 28px;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #zeit #hoergeraete .container img {
    height: 100%;
  }
}
#iframeCenter {
  margin: 0 auto;
  width: 1000px;
}
#iframeCenter:before {
  content: "";
  position: absolute;
  z-index: 1;
  display: block;
  right: 0;
  height: 120px;
  width: 220px;
  background: white;
}
@media (max-width: 1000px) {
  #iframeCenter {
    display: none;
  }
}

.btn-outline {
  color: rgb(197, 0, 62);
  border: 2px solid rgb(197, 0, 62);
  width: 100%;
  display: block;
  height: 41px;
  border-radius: 100px;
  cursor: pointer;
  margin-bottom: 10px;
  font-size: 0.925rem;
  line-height: 41px;
}
@media (max-width: 400px) {
  .btn-outline {
    font-size: 0.7rem;
  }
}
.btn-outline:hover {
  background: rgb(197, 0, 62);
  color: white;
}

.btn-outline-input {
  color: rgb(197, 0, 62);
  border: 2px solid rgb(197, 0, 62);
  width: 100%;
  display: block;
  height: 41px;
  border-radius: 100px;
  margin-bottom: 10px;
  font-size: 0.925rem;
  line-height: 41px;
  outline: none;
  text-align: center;
}
.btn-outline-input:hover {
  border: 2px solid rgb(85, 15, 80);
}

.btn {
  color: white;
  width: 100%;
  display: block;
  height: 41px;
  line-height: 41px;
  border-radius: 100px;
  cursor: pointer;
  margin: 20px 0 10px;
  font-size: 0.825rem;
  font-family: "valueBold", Helvetica, Arial, sans-serif;
  background: linear-gradient(to right, rgb(197, 0, 62), rgb(85, 15, 80));
  text-align: center;
  outline: none;
  border: none;
}
.btn:hover {
  background: linear-gradient(to right, rgb(197, 0, 62), rgb(235, 90, 45));
  color: white;
}
.btn.inactive:hover {
  background: linear-gradient(to right, rgb(197, 0, 62), rgb(85, 15, 80));
}

.btn-submit {
  color: white;
  width: 100%;
  display: block;
  line-height: 1;
  border-radius: 100px;
  cursor: pointer;
  margin: 20px 0 10px;
  font-size: 0.825rem;
  font-family: "valueBold", Helvetica, Arial, sans-serif;
  background: linear-gradient(to right, rgb(197, 0, 62), rgb(85, 15, 80));
  text-align: center;
  outline: none;
  border: none;
  padding: 10px 0;
}
.btn-submit span {
  color: inherit;
  font-size: inherit;
  display: inline-block;
  max-width: 240px;
}
.btn-submit span.large {
  font-size: 1rem;
  font-family: "valueBold", Helvetica, Arial, sans-serif;
}
.btn-submit:hover {
  background: linear-gradient(to right, rgb(197, 0, 62), rgb(235, 90, 45));
  color: white;
}
.btn-submit.inactive:hover {
  background: linear-gradient(to right, rgb(197, 0, 62), rgb(85, 15, 80));
}

.btn-large {
  color: white;
  display: inline-block;
  border-radius: 100px;
  cursor: pointer;
  padding: 10px 60px;
  margin: 20px 0 10px;
  font-size: 1.2rem;
  font-family: "valueBold", Helvetica, Arial, sans-serif;
  background: linear-gradient(to right, rgb(197, 0, 62), rgb(85, 15, 80));
  text-align: center;
  outline: none;
  border: none;
}
.btn-large:hover {
  background: linear-gradient(to right, rgb(197, 0, 62), rgb(235, 90, 45));
  color: white;
}

form {
  position: relative;
  margin-left: 20px;
  border: 2px solid rgb(197, 0, 62);
  z-index: 999;
  background: #fff;
  height: 625px;
}
@media (max-width: 500px) {
  form {
    height: 670px;
  }
}
form .formHeader {
  background: linear-gradient(to right, rgb(197, 0, 62), rgb(85, 15, 80));
  padding: 10px 10px 50px;
  text-align: center;
  position: relative;
}
form .formHeader:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 25px;
  background: url("../img/abdecker.svg") center no-repeat;
  background-size: cover;
}
@media (min-width: 670px) and (max-width: 980px) {
  form .formHeader:before {
    height: 35px;
  }
}
form .formHeader h2 {
  color: white;
  font-size: 1.8rem;
  line-height: 1.2;
  margin-bottom: 15px;
}
form .formHeader h2 span {
  font-family: "value", Helvetica, Arial, sans-serif !important;
  font-size: inherit;
  color: white;
  line-height: inherit;
}
form .formHeader h4 {
  color: white;
}
form .formHeader h4 span {
  color: inherit;
}
form .formContent {
  padding: 20px;
  max-width: 380px;
  margin: 0 auto;
  background: #fff;
  height: 70%;
}
@media (max-width: 500px) {
  form .formContent {
    height: 68%;
  }
}
form .formContent .question {
  display: none;
  text-align: center;
}
form .formContent .question.active {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
form .formContent .question input[type=radio] {
  display: none;
}
form .formContent .question input[type=radio]:checked ~ label {
  background: rgb(197, 0, 62);
  color: white;
  border: 2px solid rgb(85, 15, 80);
}
form .formContent .question strong {
  font-size: 1.2rem;
  line-height: 1.3;
}
form .formContent .question p {
  font-size: 0.925rem;
}

.alert {
  color: white;
  background: rgb(197, 0, 62);
  padding: 5px;
  border: 2px solid #790026;
}

#vollversichert {
  padding: 100px 40px;
}
#vollversichert:after {
  content: "";
  display: block;
  position: absolute;
  width: 60px;
  height: 60px;
  background: #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg) translateX(-50%);
  bottom: -50px;
  z-index: 99;
  left: 50%;
}
#vollversichert .soft-row {
  max-width: 1140px;
  margin: 0 auto;
  justify-content: space-between;
}
#vollversichert .soft-row h2 {
  margin-bottom: 60px;
}
#vollversichert .soft-row .col-1 {
  min-width: 320px;
  max-width: 320px;
  margin-left: 60px;
}
@media (max-width: 1224px) {
  #vollversichert .soft-row .col-1 {
    min-width: 90%;
    margin-bottom: 50px;
  }
  #vollversichert .soft-row .col-1:last-child {
    margin-bottom: 0px;
  }
  #vollversichert .soft-row .col-1:last-child h3 {
    margin-bottom: 15px !important;
  }
}
@media (max-width: 400px) {
  #vollversichert .soft-row .col-1 {
    min-width: 80%;
    margin-bottom: 50px;
  }
}
#vollversichert .soft-row .col-1:before {
  content: url("../img/icn_list.svg");
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  left: -60px;
}
#vollversichert .soft-row .col-1 .soft-row {
  align-items: center;
  margin-bottom: 30px;
}
#vollversichert .soft-row .col-1 .soft-row h2 {
  color: rgb(197, 0, 62);
  font-family: "value", Helvetica, Arial, sans-serif;
  flex: 1 0 0;
  margin-bottom: 0;
  margin-left: 20px;
  font-size: 2.5em;
}
@media (max-width: 787px) {
  #vollversichert .soft-row .col-1 .soft-row h2 {
    font-size: 1.3em;
  }
}
@media (max-width: 696px) {
  #vollversichert .soft-row .col-1 .soft-row h2 {
    font-size: 2em;
  }
}
#vollversichert .soft-row .col-1 .soft-row h2 strong {
  font-family: "valueBold", Helvetica, Arial, sans-serif;
  font-size: inherit;
}
#vollversichert .soft-row .col-1 p {
  font-size: 0.9rem;
}
@media (max-width: 980px) {
  #vollversichert .soft-row .col-1 p {
    font-size: 0.9rem;
  }
}
@media (max-width: 350px) {
  #vollversichert .soft-row .col-1 p {
    margin-right: 20px;
  }
}
#vollversichert .soft-row .col-1 h3 {
  font-size: 1.4rem;
  font-family: "univers";
  font-weight: bold;
  color: rgb(197, 0, 62);
}
#vollversichert .soft-row .col-1 h3 .soft-row {
  align-items: stretch;
  width: 100%;
}
#vollversichert .soft-row .col-1 h3 .soft-row .ausnahme {
  min-width: 200px;
}
#vollversichert .soft-row .col-1 h3 .soft-row .col-1 {
  padding: 10px;
}
#vollversichert .soft-row .col-1 h3 .soft-row .col-1 .zitat {
  padding: 10px;
  height: 100%;
  min-width: 170px;
  max-width: 100%;
  background: #F8F8F8;
}
#vollversichert .soft-row .col-1 h3 .soft-row .col-1 .zitat p {
  font-size: 0.7rem;
  text-align: left;
  margin: 10px 0;
}
#vollversichert .soft-row .col-1 h3 .soft-row .col-1 .zitat p.name {
  font-size: 0.6rem;
  opacity: 0.8;
  margin-bottom: 0;
}
#vollversichert .soft-row .col-1 h3 .soft-row .col-1 .zitat .sterne {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#vollversichert .soft-row .col-1 h3 .soft-row .col-1 .zitat .sterne img {
  width: 90px;
}
#vollversichert .soft-row .col-1 h3 .soft-row .col-1 .zitat .sterne p {
  font-size: 0.5rem;
}
@media (max-width: 400px) {
  #vollversichert {
    padding: 40px;
  }
}

#zusatzversichert {
  padding: 100px 40px;
  background: #F8F8F8;
}
#zusatzversichert .soft-row {
  max-width: 1140px;
  margin: 0 auto;
  justify-content: space-between;
}
#zusatzversichert .soft-row h2 {
  margin-bottom: 60px;
}
#zusatzversichert .soft-row .col-1 {
  min-width: 320px;
  max-width: 320px;
  margin-left: 60px;
}
@media (max-width: 1224px) {
  #zusatzversichert .soft-row .col-1 {
    min-width: 90%;
    margin-bottom: 50px;
  }
  #zusatzversichert .soft-row .col-1:last-child {
    margin-bottom: 0px;
  }
  #zusatzversichert .soft-row .col-1:last-child h3 {
    margin-bottom: 15px !important;
  }
  #zusatzversichert .soft-row .col-1 h3:first-of-type {
    margin-bottom: 15px !important;
  }
}
@media (max-width: 400px) {
  #zusatzversichert .soft-row .col-1 {
    min-width: 80%;
    margin-bottom: 50px;
  }
}
#zusatzversichert .soft-row .col-1:first-of-type h3, #zusatzversichert .soft-row .col-1:last-child h3 {
  margin-bottom: 43px;
}
#zusatzversichert .soft-row .col-1:before {
  content: url("../img/icn_list.svg");
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  left: -60px;
}
#zusatzversichert .soft-row .col-1 .soft-row {
  align-items: center;
  margin-bottom: 30px;
}
#zusatzversichert .soft-row .col-1 .soft-row h2 {
  color: rgb(197, 0, 62);
  font-family: "value", Helvetica, Arial, sans-serif;
  flex: 1 0 0;
  margin-bottom: 0;
  margin-left: 20px;
  font-size: 2.5em;
}
@media (max-width: 787px) {
  #zusatzversichert .soft-row .col-1 .soft-row h2 {
    font-size: 1.3em;
  }
}
@media (max-width: 696px) {
  #zusatzversichert .soft-row .col-1 .soft-row h2 {
    font-size: 2em;
  }
}
#zusatzversichert .soft-row .col-1 .soft-row h2 strong {
  font-family: "valueBold", Helvetica, Arial, sans-serif;
  font-size: inherit;
}
#zusatzversichert .soft-row .col-1 p {
  font-size: 0.9rem;
}
@media (max-width: 980px) {
  #zusatzversichert .soft-row .col-1 p {
    font-size: 0.9rem;
  }
}
@media (max-width: 350px) {
  #zusatzversichert .soft-row .col-1 p {
    margin-right: 20px;
  }
}
#zusatzversichert .soft-row .col-1 h3 {
  font-size: 1.4rem;
  font-family: "univers";
  font-weight: bold;
  color: rgb(197, 0, 62);
}
#zusatzversichert .soft-row .col-1 h3 .soft-row {
  align-items: stretch;
  width: 100%;
}
#zusatzversichert .soft-row .col-1 h3 .soft-row .ausnahme {
  min-width: 200px;
}
#zusatzversichert .soft-row .col-1 h3 .soft-row .col-1 {
  padding: 10px;
}
#zusatzversichert .soft-row .col-1 h3 .soft-row .col-1 .zitat {
  padding: 10px;
  height: 100%;
  min-width: 170px;
  max-width: 100%;
  background: #F8F8F8;
}
#zusatzversichert .soft-row .col-1 h3 .soft-row .col-1 .zitat p {
  font-size: 0.7rem;
  text-align: left;
  margin: 10px 0;
}
#zusatzversichert .soft-row .col-1 h3 .soft-row .col-1 .zitat p.name {
  font-size: 0.6rem;
  opacity: 0.8;
  margin-bottom: 0;
}
#zusatzversichert .soft-row .col-1 h3 .soft-row .col-1 .zitat .sterne {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#zusatzversichert .soft-row .col-1 h3 .soft-row .col-1 .zitat .sterne img {
  width: 90px;
}
#zusatzversichert .soft-row .col-1 h3 .soft-row .col-1 .zitat .sterne p {
  font-size: 0.5rem;
}
@media (max-width: 400px) {
  #zusatzversichert {
    padding: 40px;
  }
}

#usp {
  padding: 80px 40px;
  background: #F8F8F8;
}
#usp .soft-row {
  max-width: 1140px;
  margin: 0 auto;
  justify-content: space-between;
}
@media (max-width: 1040px) {
  #usp .soft-row {
    justify-content: center;
  }
  #usp .soft-row .col-1 {
    margin-bottom: 50px;
  }
  #usp .soft-row .col-1:last-child {
    margin-bottom: 0px;
  }
}
#usp .soft-row h2 {
  margin-bottom: 60px;
}
#usp .soft-row .col-1 {
  min-width: 320px;
  max-width: 320px;
  text-align: center;
}
#usp .soft-row .col-1 h4 {
  font-size: 1.3rem;
  margin: 30px 0;
}
#usp .soft-row .col-1 .btn-outline {
  margin: 0 auto;
  width: 180px;
}
#usp .soft-row .col-1 .soft-row {
  align-items: center;
  margin-bottom: 30px;
}
#usp .soft-row .col-1 .soft-row h2 {
  color: rgb(197, 0, 62);
  font-family: "value", Helvetica, Arial, sans-serif;
  flex: 1 0 0;
  margin-bottom: 0;
  margin-left: 20px;
  font-size: 2.5em;
}
@media (max-width: 787px) {
  #usp .soft-row .col-1 .soft-row h2 {
    font-size: 1.3em;
  }
}
@media (max-width: 696px) {
  #usp .soft-row .col-1 .soft-row h2 {
    font-size: 2em;
  }
}
#usp .soft-row .col-1 .soft-row h2 strong {
  font-family: "valueBold", Helvetica, Arial, sans-serif;
  font-size: inherit;
}
#usp .soft-row .col-1 p {
  font-size: 0.9rem;
}
@media (max-width: 980px) {
  #usp .soft-row .col-1 p {
    font-size: 0.9rem;
  }
}
@media (max-width: 350px) {
  #usp .soft-row .col-1 p {
    margin-right: 20px;
  }
}
#usp .soft-row .col-1 h3 {
  font-size: 1.4rem;
  font-family: "univers";
  font-weight: bold;
  color: rgb(197, 0, 62);
}
#usp .soft-row .col-1 h3 .soft-row {
  align-items: stretch;
  width: 100%;
}
#usp .soft-row .col-1 h3 .soft-row .ausnahme {
  min-width: 200px;
}
#usp .soft-row .col-1 h3 .soft-row .col-1 {
  padding: 10px;
}
#usp .soft-row .col-1 h3 .soft-row .col-1 .zitat {
  padding: 10px;
  height: 100%;
  min-width: 170px;
  max-width: 100%;
  background: #F8F8F8;
}
#usp .soft-row .col-1 h3 .soft-row .col-1 .zitat p {
  font-size: 0.7rem;
  text-align: left;
  margin: 10px 0;
}
#usp .soft-row .col-1 h3 .soft-row .col-1 .zitat p.name {
  font-size: 0.6rem;
  opacity: 0.8;
  margin-bottom: 0;
}
#usp .soft-row .col-1 h3 .soft-row .col-1 .zitat .sterne {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#usp .soft-row .col-1 h3 .soft-row .col-1 .zitat .sterne img {
  width: 90px;
}
#usp .soft-row .col-1 h3 .soft-row .col-1 .zitat .sterne p {
  font-size: 0.5rem;
}
@media (max-width: 400px) {
  #usp {
    padding: 40px;
  }
}

#call {
  padding: 60px 40px;
  background: linear-gradient(to right, rgb(197, 0, 62), rgb(85, 15, 80));
}
#call .soft-row {
  max-width: 1140px;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
}
#call .soft-row h2 {
  margin-bottom: 60px;
}
#call .soft-row .col-1 {
  min-width: 320px;
}
@media (max-width: 694px) {
  #call .soft-row .col-1 {
    text-align: center;
  }
}
#call .soft-row .col-1 .soft-row {
  align-items: center;
  margin-bottom: 30px;
}
#call .soft-row .col-1 .soft-row h2 {
  color: rgb(197, 0, 62);
  font-family: "value", Helvetica, Arial, sans-serif;
  flex: 1 0 0;
  margin-bottom: 0;
  margin-left: 20px;
  font-size: 2.5em;
}
@media (max-width: 787px) {
  #call .soft-row .col-1 .soft-row h2 {
    font-size: 1.3em;
  }
}
@media (max-width: 696px) {
  #call .soft-row .col-1 .soft-row h2 {
    font-size: 2em;
  }
}
#call .soft-row .col-1 .soft-row h2 strong {
  font-family: "valueBold", Helvetica, Arial, sans-serif;
  font-size: inherit;
}
#call .soft-row .col-1 p {
  font-size: 0.9rem;
  color: white;
}
@media (max-width: 980px) {
  #call .soft-row .col-1 p {
    font-size: 0.9rem;
  }
}
@media (max-width: 350px) {
  #call .soft-row .col-1 p {
    margin-right: 20px;
  }
}
#call .soft-row .col-1 h3 {
  font-size: 3rem;
  font-family: "valueBold";
  color: white;
  margin-bottom: 50px;
}
#call .soft-row .col-1 h5 {
  font-size: 1.3rem;
  font-family: "value";
  color: white;
  margin-bottom: 30px;
}
#call .soft-row .col-1 h5 .soft-row {
  align-items: stretch;
  width: 100%;
}
#call .soft-row .col-1 h5 .soft-row .ausnahme {
  min-width: 200px;
}
#call .soft-row .col-1 h5 .soft-row .col-1 {
  padding: 10px;
}
#call .soft-row .col-1 h5 .soft-row .col-1 .zitat {
  padding: 10px;
  height: 100%;
  min-width: 170px;
  max-width: 100%;
  background: #F8F8F8;
}
#call .soft-row .col-1 h5 .soft-row .col-1 .zitat p {
  font-size: 0.7rem;
  text-align: left;
  margin: 10px 0;
}
#call .soft-row .col-1 h5 .soft-row .col-1 .zitat p.name {
  font-size: 0.6rem;
  opacity: 0.8;
  margin-bottom: 0;
}
#call .soft-row .col-1 h5 .soft-row .col-1 .zitat .sterne {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#call .soft-row .col-1 h5 .soft-row .col-1 .zitat .sterne img {
  width: 90px;
}
#call .soft-row .col-1 h5 .soft-row .col-1 .zitat .sterne p {
  font-size: 0.5rem;
}
@media (max-width: 400px) {
  #call {
    padding: 15px;
  }
}

#danke {
  min-height: calc(100vh - 130px);
  width: 100%;
  background: linear-gradient(to bottom, rgb(197, 0, 62), rgb(85, 15, 80));
  display: flex;
  align-items: center;
}
#danke .danke {
  max-width: 1140px;
  width: 90%;
  padding: 30px;
  margin: 0 auto;
}
#danke .danke h1 {
  color: white;
  font-size: 4rem;
  line-height: 1;
}
#danke .danke h3 {
  color: white;
  font-size: 2rem;
}
#danke .danke a {
  background: #fff;
  color: rgb(197, 0, 62);
  padding: 20px 40px;
  border-radius: 50px;
  margin-top: 30px;
  font-size: 1.3rem;
  font-family: "valueBold", Helvetica, Arial, sans-serif;
  display: inline-block;
}
@media (max-width: 980px) {
  #danke .danke h1 {
    font-size: 2rem;
  }
  #danke .danke h3 {
    font-size: 1.4rem;
  }
}

#corona {
  background: rgb(197, 0, 62);
}
#corona .content {
  padding: 10px;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}
#corona * {
  color: white;
}
#corona h2 {
  text-align: center;
  margin: 0;
  font-size: 22px;
  white-space: break-spaces;
}
@media (min-width: 840px) {
  #corona h2 {
    font-size: 25px;
    line-height: 1.2;
  }
}
#corona .corona--grid {
  display: grid;
  align-items: center;
  grid-template-areas: "text text text text" "desinfektion waschen handschuh mundschutz";
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  grid-gap: 10px;
}
#corona .corona--grid .grid--item {
  text-align: center;
}
#corona .corona--grid .grid--item img {
  max-width: 80px;
}
#corona .corona--grid .desinfektion {
  grid-area: desinfektion;
}
#corona .corona--grid .waschen {
  grid-area: waschen;
}
#corona .corona--grid .text {
  -ms-grid-row: 1 !important;
  -ms-grid-column: 1 !important;
  -ms-grid-column-span: 7 !important;
  grid-area: text;
}
#corona .corona--grid .handschuh {
  grid-area: handschuh;
}
#corona .corona--grid .mundschutz {
  grid-area: mundschutz;
}
@media (min-width: 840px) {
  #corona .corona--grid {
    grid-template-areas: "desinfektion waschen text handschuh mundschutz";
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
  }
  #corona .corona--grid .grid--item.text {
    -ms-grid-column: 3 !important;
    -ms-grid-column-span: 1;
  }
}

.cookie--badge {
  position: fixed;
  right: 10px;
  bottom: 10px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(197, 0, 62);
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}
@media (min-width: 1200px) {
  .cookie--badge {
    z-index: 20;
  }
}

@media (max-width: 600px) {
  .CybotCookiebotDialogBodyLevelButtonWrapper {
    display: block !important;
  }
}
#CybotCookiebotDialogDetailFooter a {
  font-size: inherit !important;
}

#CybotCookiebotDialogDetailBodyContentTabs a {
  font-size: 12px !important;
}

#CybotCookiebotDialog {
  position: fixed;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 90% !important;
  max-width: 610px !important;
  box-shadow: 0 0 100vw 100vw rgba(0, 0, 0, 0.5) !important;
  padding: 20px !important;
  box-sizing: border-box !important;
}
#CybotCookiebotDialog * {
  font-size: 9.5pt;
}
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelDetailsButton {
  font-weight: normal !important;
  text-decoration: none !important;
}
#CybotCookiebotDialog #CybotCookiebotDialogBody {
  padding: 0;
  width: 100%;
  max-width: 100%;
}
#CybotCookiebotDialog #CybotCookiebotDialogPoweredbyLink {
  display: none;
}
#CybotCookiebotDialog #CybotCookiebotDialogBodyContent {
  padding: 0;
  padding-bottom: 20px;
}
#CybotCookiebotDialog * {
  font-family: #707070 !important;
}
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelWrapper {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: flex-start;
}
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonsSelectPane {
  padding: 0 !important;
  border: none !important;
}
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelDetailsWrapper {
  padding: 0 !important;
  background: white !important;
  border: none !important;
  text-align: right !important;
}
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper {
  order: 2;
  display: flex !important;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  display: flex !important;
  padding: 10px 20px !important;
  align-items: center;
  justify-content: center;
  margin: 10px 0 0 10px !important;
}
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
  background: white !important;
  border: none !important;
  padding-left: 0 !important;
  padding-bottom: 0 !important;
  color: #707070 !important;
  margin-left: 0 !important;
  justify-content: flex-start;
  font-weight: normal !important;
}
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  background: rgb(197, 0, 62) !important;
  border: 1px solid rgb(197, 0, 62) !important;
}
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtons {
  margin: 0 !important;
  width: 100%;
}