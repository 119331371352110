nav
	height: 100px
	width: 100%
	background: #ffffff
	position: fixed
	z-index: 99999
	border-bottom: 2px solid $grau
	@media (max-width: 1250px)
		padding: 0 30px
	.container
		display: flex
		align-items: center
		// padding-left: 30px
		justify-content: space-between
		max-width: 1200px
		margin: 0 auto
		height: 100%
		
		#logo   
			height: 50px
			display: block
			img
				height: 100%
			p 
				display: none !important
				color: white
				font-size: 1em
				line-height: 0
				text-align: center
				margin-top: 4px
				@media (max-width: 1024px)
					font-size: 0.8em
		#logo2
			position: relative
			img
				height: 50px
				display: block
				@media (max-width: 700px)
					height: 30px

		#trennstrich
			width: 4px
			height: 60%
			margin: 0 25px
			background: $grau


		#menu
			height: 100%
			width: 100%
			display: flex
			align-items: center
			justify-content: flex-end
			.zufriedenheit
				max-height: 85%
				display: inline-block
				margin-right: 20px
			.hotline
				// padding: 10px 0
				height: 100%
				align-items: center
				display: grid
				text-align: end
				@media (max-width: 1140px)
					padding: 10px 20px
		
				h3
					font-size: 1.8rem
					color: $rot
					margin-bottom: 0px
					display: inline-block
					.phone
						width: 27px
						position: relative
						top: 3px

				.top-menu-wrap
					display: flex
					align-items: center
					.top-menu
						font-size: 0.8rem
						margin-left: 20px
						display: flex
						align-items: flex-end
						line-height: 1
						&:hover
							color: $rot
						&:before
							display: inline-flex
							position: relative
							width: 20px
							height: 20px
							margin-right: 10px
					.terminIcon
						&:before
							content: url('../img/i_termin.svg')
					.filialIcon
						&:before
							content: url('../img/i_filiale.svg')
					.hoertestIcon
						&:before
							content: url('../img/i_hoertest.svg')


	@media (max-width: 940px)
		height: auto
		padding: 0
		.container
			padding-left: 0
			display: inline-flex
			text-align: center
			flex-wrap: wrap
			justify-content: center
			#trennstrich
				width: 2px
				height: 58px
				margin: 0
			#logo, #logo2
				position: relative
				height: 70px
				margin: 0
				padding: 21px
				img 
					height: 28px
			#logo2
				padding: 0 21px
				display: flex
				align-items: center
				img
					height: 30px
			#menu
				background: #dedede
				.zufriedenheit
					display: none
					width: 100%
				.hotline
					padding: 12px
					width: 100%
					margin-left: 0
					display: flex
					align-items: center
					height: auto
					justify-content: center
					margin: 0
					flex-wrap: wrap
					a:last-of-type
						margin-left: 50px
					.top-menu-wrap
						min-width: 420px
						@media (max-width: 695px)
							margin-bottom: 20px
						@media (max-width: 500px)
							min-width: 300px
					h3
						font-size: 1.3rem
						margin-right: 20px
						margin-bottom: 0
						.phone
							width: 20px
					.sternchen
						margin-bottom: 0
					p:not(.sternchen)
						display: none
						font-size: 0.825rem

#huk--link,
#vrk--link
	position: absolute
	// border: 1px solid red
	top: 0
	height: 100%
#huk--link
	left: 0
	width: 70%
#vrk--link
	left: 70%
	width: 30%