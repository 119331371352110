header
    width: 100%
    .image
        width: 100%
        position: absolute
        left: 0
        top: 0
        height: 100%
        background: url('../img/hero-image.jpg') left center no-repeat
        background-size: cover
    .content
        // max-width: 1140px
        margin: 0 auto
        height: 45vw
        max-height: 750px
        min-height: 600px
        display: flex 
        flex-wrap: wrap
        #scroll-down
            bottom: 5%
            margin: 0 auto
            align-self: flex-end
            width: 40px
            // animation: bounce 2s infinite
            // @keyframes bounce 
            //     0%, 20%, 50%, 80%, 100%
            //         transform: translateY(0)
            //     40% 
            //         transform: translateY(-20px)
                
            //     60% 
            //         transform: translateY(-10px)
            
        .header-zitat
            background: rgba(255,255,255,0.8)
            position: absolute
            bottom: 15%
            left: 0
            padding: 15px 30px
            max-width: 1000px
            p
                font-size: 2vw
                font-family: 'value'
                color: black
            strong
                font-size: 2.25vw
                font-family: 'valueBold'
                color: black
            @media (max-width: 600px)
                p
                    font-size: 3vw
                strong
                    font-size: 3.25vw
        @media (max-width: 980px)
            max-height: 100%
            min-height: 100%
            position: absolute
            bottom: 0
            width: 100%
        .col-1
            min-width: 300px 
            h1  
                margin-top: 50px
                color: $rot
                font-size: 2.3rem
                margin-left: 60px
                // max-width: 500px
                margin-bottom: 50px
            .headline
                h2 
                    color: white
                    margin-left: 60px
                    font-family: chalk
                    font-weight: 100
                    font-size: 2.5rem

    @media (max-width: 600px)
        .image
            height: 60vh !important
            background: url('../img/hero-image.jpg') center center no-repeat
            background-size: cover

    @media (max-width: 980px)
        .image
            width: 100%
            height: 50vw
            position: relative

        .content
            height: auto
            padding-bottom: 20px
            
            .col-1
                h1
                    margin: 0
                    padding: 20px
                    font-size: 1.8rem
                    color: $rot
                    text-align: center
                    position: absolute
                    top: -43%
                    width: 300px
                    text-align: left
                    z-index: 9999999999
                    right: 5%
                    @media (max-width: 600px)
                        top: -27%
                        right: -3%
                        font-size: 1.4rem
                        width: 200px
                    @media (max-width: 350px)
                        top: -25%
                        right: -9%
                        font-size: 1.4rem
                        width: 200px
                .headline
                    h2 
                        color: black
                        text-align: center
                form
                    margin-left: 0
                    margin: 0 10px 10px 10px
                    @media (max-width: 500px)
                        margin: 10px 10px
                    
                        

hr
    width: 100%
    margin: 0
    padding: 0
    background: $gradient3
    height: 7px
.headercontent
    padding: 40px
    .soft-row
        max-width: 1140px
        margin: 0 auto
        padding: 40px 0
        .col-1
            ul
                li
                    font-size: 1.5rem
                    font-family: $font-family-headline
                    margin: 0 0 20px 40px 
                    position: relative
                    &:last-child
                        margin-bottom: 0
                    &:before
                        content: ""
                        width: 30px
                        height: 25px
                        position: absolute
                        left: -40px
                        top: 3px
                        background: url('../img/icn_list.svg') center no-repeat
                        background-size: contain
    @media (max-width: 980px)
        .soft-row
            display: block
            .col-1
                ul
                    li
                        font-size: 1rem
                        margin-left: 20px
                        &:before
                            width: 20px
                            top: 2px
                            left: -25px
    @media (max-width: 400px)
        .soft-row
            padding: 0px

